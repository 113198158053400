import {Subject} from 'rxjs';

const subject = new Subject<string>();

export function raiseError(error: string) {
    subject.next(error);
    setTimeout(() => subject.next(''), 5000);
}

export function getErrorObservable() {
    return subject.asObservable();
}
