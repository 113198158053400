import {OnboardingCompleteEndpoint, SettingsEndpoint} from '../../constants/api';
import {SettingsProps} from '../../types/user-settings.type';
import {Api} from '../api';
import {RuntimeStorage} from '../runtime-storage';
import {DefaultDepthOption} from '../../constants/analyse-depth';
import {raiseNotification} from '../notify.service';

export class UserSettings extends Api {

    public getDefault(): SettingsProps {
        return {
            sendNoSummary: false,
            sendSummary: true,
            redirectEmails: '',
            whiteEmailList: [],
            onboardingComplete: false,
            isPremium: false,
            premiumUntil: 0,
            defaultSummaryDepth: DefaultDepthOption.key,
            defaultMaxSummaryLength: 0,
            defaultMinSummaryLength: 200,
            defaultTranslateTo: '',
        };
    }

    public async isPremium(): Promise<boolean> {
        const settings = await this.getSettings();

        const now = new Date().getTime();
        const premiumUntil = (settings.premiumUntil || 0) * 1000; // unix timestamp should be converted to milliseconds

        return (settings.isPremium && premiumUntil - now > 0) || false;
    }

    public async onboardingComplete() {
        const response = await this.fetch(OnboardingCompleteEndpoint, {
            method: 'PUT',
        });

        return await response?.json();
    }

    public async getSettings(): Promise<SettingsProps & { authError: boolean }> {
        if (RuntimeStorage.getItem('settings')) {
            return RuntimeStorage.getItem('settings');
        }
        const response = await this.fetch(SettingsEndpoint, {
            method: 'GET',
        });

        const data = await response?.json();
        const defaultSettings = this.getDefault();

        const settings = {
            ...defaultSettings,
            ...data,
            authError: response?.status === 401,
        };

        RuntimeStorage.setItem('settings', settings);

        return settings;
    }

    public async updateSettings(settings: SettingsProps) {
        const response = await this.fetch(SettingsEndpoint, {
            method: 'PUT',
            body: JSON.stringify(settings),
        });

        if (response?.status === 200) {
            raiseNotification('Settings updated');
            RuntimeStorage.setItem('settings', {
                ...settings,
            });
        }

        return await response?.json();
    }
}
