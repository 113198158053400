import {GoogleMeetWithMessages} from '../../types/full-meeting.type';
import {Meet} from '../../types/meet.type';
import {
    MeetingByIDEndpoint,
    MeetingListEndpoint, MessageAIFixEndpoint, MessageEncryptEndpoint,
    MessageMarkEndpoint,
    PromptEndpoint,
    RePromptEndpoint, RePromptProEndpoint,
    SummaryListEndpoint,
    TranslateEndpoint
} from '../../constants/api';
import {TranslateType} from '../../constants/translate';
import {Summary} from '../../types/summary';
import {Api} from '../api';
import {MBoxMeetingLimit} from '../../constants';
import {SummarySettings} from '../../types/summary-settings';
import {raiseError} from '../error.service';

export class MeetService extends Api {

    public async encryptMessage(messageId: string) {
        const response = await this.fetch(`${MessageEncryptEndpoint}/${messageId}`, {
            method: 'PUT',
        });

        return await response?.json();
    }

    public async getGroupedMeetList(limit = MBoxMeetingLimit, offset?: string): Promise<{ [k: string]: Meet[] }> {
        const meets = await this.getMeetList(limit, offset);
        if (!meets || !meets.length) {
            return {};
        }

        const groupedMeets: { [key: string]: Meet[] } = {};
        meets?.forEach((meet) => {
            meet.users = meet.users || [];
            const date = new Date(meet.date).toLocaleDateString('en-US', {
                year: 'numeric',
                month: 'long',
                day: 'numeric'
            });
            if (!groupedMeets[date]) {
                groupedMeets[date] = [];
            }
            groupedMeets[date].push(meet);
        });

        return groupedMeets;
    }

    public async getMeetList(limit = 100, offset?: string): Promise<Meet[]> {
        try {
            const params = new URLSearchParams();
            params.append('limit', limit.toString());
            if (offset) {
                params.append('offset', offset);
            }

            const response = await this.fetch(`${MeetingListEndpoint}?${params.toString()}`, {
                method: 'GET',
            });

            return await response?.json() || [];
        } catch (ex) {
            console.log('Error:', ex);

            return [];
        }

    }

    public async removeMeet(meetKey: string) {
        const response = await this.fetch(`${MeetingByIDEndpoint}${meetKey}`, {
            method: 'DELETE',
        });

        return !!await response?.json();
    }


    public async promptMeet(meetId: string, promptKey: string) {
        const response = await this.fetch(PromptEndpoint, {
            method: 'POST',
            body: JSON.stringify({
                meetKey: meetId,
                promptKey
            }),
        });

        return await response?.json();
    }

    public async customPrompt(meetId: string, prompt: string) {
        const response = await this.fetch(PromptEndpoint, {
            method: 'POST',
            body: JSON.stringify({
                meetKey: meetId,
                promptKey: 'custom',
                customPrompt: prompt
            }),
        });

        const summary = await response?.json();

        if (!summary) {
            raiseError('Error occur. Try again later or contact support.');
        }

        return summary;
    }

    public async translateSummary(meetId: string, summaryKey: string, translateTo: string) {
        const response = await this.fetch(TranslateEndpoint, {
            method: 'POST',
            body: JSON.stringify({
                meetKey: meetId,
                type: TranslateType.Summary,
                summaryKey,
                languageKey: translateTo
            }),
        });

        const summary = await response?.json();
        if (!summary) {
            raiseError('Error occur. Try again later or contact support.');
        }

        return this.prepareSummary(summary);
    }

    public async updateSummary(meetId: string, docId: string, opts: {
        promptKey: string,
        settings?: SummarySettings
    }) {
        const endpoint = opts?.settings ? `${RePromptProEndpoint}/${docId}` : `${RePromptEndpoint}/${docId}`;
        const response = await this.fetch(endpoint, {
            method: 'PUT',
            body: JSON.stringify({
                meetKey: meetId,
                promptKey: opts.promptKey,
                settings: opts.settings
            }),
        });

        const summary = await response?.json();
        if (!summary) {
            raiseError('Error occur. Try again later or contact support.');
        }

        return this.prepareSummary(summary);
    }

    async getById(meetId: string): Promise<GoogleMeetWithMessages | null> {
        const response = await this.fetch(`${MeetingByIDEndpoint}${meetId}`, {
            method: 'GET',

        });
        const data = await response?.json();

        return {
            ...(data?.meet || {}),
            messages: data?.messages || [],
        };

    }

    async getSummaries(meetId: string): Promise<{
        summaries: Summary[],
        summaryDisabled: boolean
    }> {
        const response = await this.fetch(`${SummaryListEndpoint}/${meetId}`, {
            method: 'GET',
        });

        const data = await response?.json();

        if (!data) {
            return {summaries: [], summaryDisabled: false}
        }

        const summaries = data?.summaries?.map((s: Summary) => this.prepareSummary(s)) || [];

        return {
            summaries, summaryDisabled: data?.summariesDisabled || false
        }
    }

    public async markMessage(messageKey: string, markType: string[]) {
        const response = await this.fetch(`${MessageMarkEndpoint}/${messageKey}`, {
            method: 'PUT',
            body: JSON.stringify({marks: markType}),
        });

        return await response?.json();
    }

    public async aiFixMessage(messageId: string) {
        const response = await this.fetch(`${MessageAIFixEndpoint}/${messageId}`, {
            method: 'PUT',
        });

        if (response?.status !== 200) {
            return null;
        }

        return await response?.json();
    }

    private isHTML(str: string) {
        const doc = new DOMParser().parseFromString(str, "text/html");
        return Array.from(doc.body.childNodes).some(node => node.nodeType === 1);
    }

    private prepareSummary(s: Summary): Summary {
        if (s.translations) {
            for (const key in s.translations) {
                if (s.translations[key].text && !this.isHTML(s.translations[key].text)) {
                    s.translations[key].text = s.translations[key].text.replace(/\n/g, '<br/>');
                }

                if (s.translations[key].text && this.isHTML(s.translations[key].text)) {
                    s.translations[key].text = this.removeBrTagsFromList(s.translations[key].text);
                }
            }
        }

        if (s.text && !this.isHTML(s.text)) {
            s.text = s.text.replace(/\n/g, '<br/>');
        }

        if (s.text && this.isHTML(s.text)) {
            s.text = this.removeBrTagsFromList(s.text);
        }

        return s;
    }

    private removeBrTagsFromList(htmlString: string): string {
        // Create a temporary DOM element to parse the HTML string
        const tempDiv = document.createElement('div');
        tempDiv.innerHTML = htmlString;

        // find all br tags
        const brTags = tempDiv.querySelectorAll('br');

        // remove a duplication of br tags
        for (let i = brTags.length - 1; i >= 1; i--) {
            const currentBr = brTags[i];
            const previousBr = brTags[i - 1];

            if (previousBr.nextSibling === currentBr) {
                previousBr.parentNode?.removeChild(currentBr);
            }
        }

        // Select all list items (li) within the temporary div
        const listItems = tempDiv.querySelectorAll('ul');

        // Iterate over each list item
        listItems.forEach(listItem => {
            // Select all <br> tags within the current list item
            const brTags = listItem.querySelectorAll('br');

            // Remove each <br> tag
            brTags.forEach(brTag => {
                brTag.parentNode?.removeChild(brTag);
            });
        });

        // Return the modified HTML string
        return tempDiv.innerHTML;
    }
}
